<template>
    <div class="switch-container">
        <span v-text="title" />
        <label v-if="!onOffMode" v-text="options[0]" />
        <input
            type="checkbox"
            :class="`switcher ${onOffMode ? 'on-off' : ''}`"
            :checked="toggle"
            @click="toggle = !toggle"
        />
        <label v-if="!onOffMode" v-text="options[1]" />
    </div>
</template>

<script>
export default {
    name: 'Switch',
    props: {
        modelValue: {
            type: Boolean,
            default: function () {
                return false
            }
        },
        title: {
            type: String,
            default: ''
        },
        onOffMode: {
            type: Boolean,
            default: true
        },
        options: {
            type: Array,
            default: function () {
                return ['', '']
            }
        }
    },
    computed: {
        toggle: {
            get() {
                return this.modelValue
            },
            set(val) {
                this.$emit('update:modelValue', val)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.switch-container {
    display: flex;
    align-items: center;
    span,
    label {
        font-size: 16px;
        color: $sixth-black;
    }
}

.switcher {
    position: relative;
    width: 30px;
    height: 16px;
    margin: 0 5px;
    visibility: hidden;
    cursor: pointer;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 16px;
        background-color: $primary-blue;
        border-radius: 10px;
        visibility: visible;
    }
    &:after {
        content: '';
        position: absolute;
        display: block;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        background-color: $primary-white;
        border-radius: 50%;
        visibility: visible;
        transition: left 0.3s ease-in-out;
    }
    &:checked:after {
        left: 14px;
    }
    &.on-off {
        margin: 0px;
        &:before {
            background-color: $eighth-grey;
            transition: background-color 0.3s ease-in-out;
        }
        &:checked:before {
            background-color: $primary-blue;
        }
    }
}
</style>
